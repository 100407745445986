













































































































































































































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Vue } from 'vue-property-decorator'
import { AccountViewModel } from '../viewmodels/account-viewmodel'
import timezones from '@/constants/timezones.json'
import { profileController } from '../../viewmodels/profile-viewmodel'
import { accountSettingController } from '@/components/auth/account-setting-controller'
import { localData } from '@/stores/local-data'

@Observer
@Component({
  components: {},
})
export default class Account extends Vue {
  @Provide() vm = new AccountViewModel()
  profileController = profileController
  wallet = walletStore
  timezones = timezones
  controller = accountSettingController

  async updateTimeZone() {
    await this.vm.updateTimezone()
    await profileController.fetchUserInfo()
  }

  async updateEmail() {
    await this.vm.updateEmailProfile()
    await profileController.fetchUserInfo()
  }

  async updateUserName() {
    await this.vm.updateUserName()
    await profileController.fetchUserInfo()
  }

  async updateUserAccountName() {
    await this.vm.updateUserAccountName()
    await profileController.fetchUserInfo()
  }

  openKycDialog() {
    //
  }

  async connectSocial(provider: string) {
    localData.setLinkEmail2Wallet({ walletAddress: walletStore.account, provider })
    window.open(`${process.env.VUE_APP_API_ENDPOINT}/connect/${provider}`, '_self')
  }
}
