import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import timezones from '@/constants/timezones.json'
import { isEmpty } from 'lodash'

export class AccountViewModel {
  @observable userProfile
  @observable isEnableChangeEmail = false
  @observable emailInput = ''
  @observable emailInputError = false
  @observable updateEmailProfileLoading = false

  @observable accountNameInput = ''
  @observable updateAccountNameLoading = false
  @observable isEnableChangeAccountName = false

  @observable timeZoneInputError = ''
  @observable timeZoneInput?: string = ''
  @observable updateTimezoneLoading = false

  @observable isEnableChangeUserName = false
  @observable userNameInput = ''
  @observable updateUserNameLoading = false

  @observable allowEditEmail = false

  constructor() {
    this.fetchUserInfo()
  }

  @asyncAction *fetchUserInfo() {
    try {
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        this.allowEditEmail = !this.userProfile?.apple_user && !this.userProfile?.google_user
        console.log('==========', this.allowEditEmail)
        this.emailInput = walletStore.userProfile?.email || ''

        this.initValue()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound initValue() {
    this.emailInput = this.email
    this.accountNameInput = this.accountName
    this.userNameInput = this.userName
    this.timeZoneInput = this.userProfile?.timeZone
    this.setDefaultTimeZone()
  }

  @action setDefaultTimeZone() {
    this.timeZoneInputError = ''
    if (this.timeZoneInput) return
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
    this.timeZoneInput = timezones.find((item) => item.toLocaleLowerCase() === timeZone)
  }

  @action.bound changetimeZoneInput(input) {
    this.timeZoneInputError = ''
    this.timeZoneInput = input
  }

  @action.bound onEmailAddressChange(val = '') {
    this.emailInput = val
  }

  @asyncAction *updateTimezone() {
    if (isEmpty(this.timeZoneInput)) {
      this.timeZoneInputError = 'This field is required'
      return
    }

    const payload = {
      id: this.userProfile?._id,
      timeZone: this.timeZoneInput,
    }
    try {
      this.updateTimezoneLoading = true
      this.userProfile = yield apiService.profiles.updateUserProfile(payload)
      walletStore.checkUserWallet()
      snackController.success('Update timezone successfully')
      this.updateTimezoneLoading = false
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.updateTimezoneLoading = false
    }
  }

  @action.bound enableChangeEmail() {
    this.isEnableChangeEmail = true
  }

  @action.bound enableChangeAccountName() {
    this.isEnableChangeAccountName = true
  }

  @action.bound enableChangeUserName() {
    this.isEnableChangeUserName = true
  }

  @asyncAction *updateEmailProfile() {
    // if (!this.checkEmailAndAgreePolicy()) return
    try {
      this.updateEmailProfileLoading = true

      const payload = {
        id: walletStore.userProfile?._id,
        email: this.emailInput,
      }
      yield apiService.profiles.updateUserProfile(payload)
      walletStore.checkUserWallet()
      snackController.success('Modify successfully')
      this.isEnableChangeEmail = false
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.updateEmailProfileLoading = false
    }
  }

  @asyncAction *updateUserName() {
    // if (!this.checkEmailAndAgreePolicy()) return
    try {
      this.updateUserNameLoading = true

      const payload = {
        id: walletStore.userProfile?._id,
        username: this.userNameInput,
      }
      yield apiService.profiles.updateUserProfile(payload)
      walletStore.checkUserWallet()
      snackController.success('Modify successfully')
      this.isEnableChangeUserName = false
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.updateUserNameLoading = false
    }
  }

  @asyncAction *updateUserAccountName() {
    try {
      this.updateAccountNameLoading = true
      const payload = {
        id: walletStore.userProfile?._id,
        accountName: this.accountNameInput,
      }
      yield apiService.profiles.updateUserProfile(payload)
      yield appProvider.router.replace(`/profile/${this.accountNameInput}/setting/account`)
      snackController.success('Modify successfully')
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.updateAccountNameLoading = false
    }
  }

  @action.bound changeEmailInput(val: string) {
    if (this.emailInputError) this.emailInputError = false
    this.emailInput = val
  }

  @action.bound changeAccountNameInput(val: string) {
    // if (this.emailInputError) this.emailInputError = false
    this.accountNameInput = val
  }

  @action.bound changeUserNameInput(val: string) {
    this.userNameInput = val
  }

  @computed get shortWalletAddress() {
    if (!this.userProfile?.walletAddress) return ''
    return (
      this.userProfile.walletAddress.substr(0, 15) +
      '...' +
      this.userProfile.walletAddress.substr(this.userProfile?.walletAddress.length - 8)
    )
  }

  @computed get email() {
    return this.userProfile?.email
  }

  @computed get accountName() {
    return this.userProfile?.unique_id
  }

  @computed get userName() {
    return this.userProfile?.username
  }

  @computed get timezoneProfile() {
    return this.userProfile?.timeZone
  }
  @computed get validToUpdateTimezone() {
    return this.timezoneProfile !== this.timeZoneInput
  }
}
